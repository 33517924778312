
/deep/ .nowrap {
  white-space: nowrap;
}
/deep/ .product-block {
  display: flex;
  justify-content: space-between;
  height: 131px;

  .product-names {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
    width: 100px;
    padding: 0.5rem;
    margin-left: 15px;
    background-color: var(--table-tr-bg);

    .one-product & {
      width: 100%;
      margin-left: 0;
    }
  }
}

.block-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 50px;

  &__left {
    position: absolute;
    left: 293px;
  }

  &__right {
    position: absolute;
    right: 25px;
  }

  ._full-size & {
    margin-bottom: 0;
    padding: 1rem;
    width: 100%;
    bottom: 0;
    margin-top: 0;

    .block-actions__right {
      position: unset;
    }
  }
}
