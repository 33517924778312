
.multiselect {
  box-sizing: inherit;
  &.custom-select-sm {
    min-height: calc(1.5em + 0.5rem + 2px);
    font-size: 0.875rem;
    /deep/ .multiselect__tags {
      .multiselect__tag {
        margin-bottom: 0;
        margin-top: 1px;
        padding: 2px 26px 2px 10px;
        .multiselect__tag-icon {
          line-height: 18px;
        }
      }
      .multiselect__strong {
        margin-bottom: 0;
      }
    }
    /deep/ .multiselect__placeholder {
      padding-top: 0;
    }
  }

  /deep/ .multiselect__tags {
    border: none;
    border-radius: 0.25rem;
    padding: 0;
    min-height: unset;

    .multiselect__tag {
      margin-bottom: 0;
    }
  }

  /deep/ .multiselect__select {
    display: none;
  }

  /deep/ .multiselect__placeholder {
    margin: 0;
    color: #495057;
  }
}
