
.product-brand-table {
  border-radius: 24px;
  .month-item {
    width: 100px;
    background-color: #fff;
    padding: 8px 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    text-align: center;
    &.active {
      background-color: #FFFFFF4D;
    }
  }
}
